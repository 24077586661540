.Card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  align-self: stretch;
  background-color: #fff;
  border: 1px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  gap: 8px;
  margin-bottom: 12px;
}

.Title {
  color: #262626;
  font-weight: 500;
  font-size: 16px;
}

.Input {
  display: flex;
  height: 40px;
  padding: 0 12px;
  gap: 4px;
  align-self: stretch;
  border: 1px solid #d8d8d8;
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
}

.Input::placeholder {
  color: #aaa;
}

.AppList {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 238px;
  padding: 12px 0;
}

.AppItemContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  max-width: 238px;
}

.AppItemInfo {
  flex-grow: 1;
  overflow: hidden;
}

.AppItemIcon {
  min-width: 48px;
}

.AppItemName {
  font-size: 14px;
  color: #363636;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.AppItemDescription {
  font-size: 13px;
  color: #999;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Divider {
  width: 100%;
  height: 1px;
  background-color: #d8d8d8;
  margin: 12px 0;
}
