.memo-editor-container ol,
.memo-editor-container ul {
  padding-left: 21px;
}

.memo-editor-container ol {
  list-style-type: decimal;
  padding-left: 18px;
}

.memo-editor-container ol li {
  padding-left: 3px;
}

.memo-editor-container ul {
  list-style-type: disc;
}

.memo-editor-container ul[data-type='taskList'] {
  list-style: none;
  margin-left: 0;
  padding: 0;
}

.memo-editor-container .task-list-item {
  list-style: none;
}

.memo-editor-container .task-list-item label {
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  position: relative;
}

.memo-editor-container .task-list-item input[type='checkbox'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  width: 16px;
  height: 16px;
  border: 1px solid #c5c5c5;
  border-radius: 4px;
  position: absolute;
  top: 4px;
  cursor: pointer;
}

.memo-editor-container .task-list-item input[type='checkbox']:checked {
  background-color: #363636;
  border-color: #363636;
  color: #fff;
}

.memo-editor-container .task-list-item input[type='checkbox']:checked::before {
  content: '✓';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: #fff;
}

.memo-editor-container .task-list-item div {
  display: inline-block;
  vertical-align: top;
  max-width: calc(100% - 24px);
  word-wrap: break-word;
}

.memo-editor-container.is-edit {
  pointer-events: auto !important;
}

.memo-editor-container .task-list-item[data-checked='true'] p {
  text-decoration: line-through;
}

.memo-editor-container .task-list-item[data-checked='true'] p {
  color: #999999;
}

.memo-editor-container .task-list-item p {
  margin: 0;
  min-width: 1em;
}

.memo-editor-container :focus {
  outline: none;
}

.memo-editor-container .task-list-item div:hover,
.memo-editor-container p {
  cursor: text;
}

.memo-editor-container {
  color: #676361;
  line-height: 150%;
  display: inline-block;
  vertical-align: top;
}

.memo-editor-container p {
  font-size: 14px;
}

.memo-editor-container s {
  text-decoration: line-through;
}

.memo-editor-container a {
  color: #4a67cb;
  text-decoration: underline;
}

.memo-editor-container .spellcheck-underline {
  text-decoration: none !important;
}

/* placeholder */
p.is-editor-empty:first-child::before {
  color: #999;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
