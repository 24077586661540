.Wrapper {
  display: flex;
  flex-direction: column;
  width: 350px;
  height: 100%;
  background-color: #f9fbfc;
  border-radius: 24px 24px 0px 0px;
  position: relative;
}

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding: 24px;
  border-bottom: 1px solid #f2f2f2;
}

.HeaderTitle {
  font-size: 20px;
  font-weight: 500;
  color: #363636;
  line-height: 150%;
}

.HeaderButton {
  width: 32px;
  height: 32px;
  padding: 4px;
  background-color: transparent;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.HeaderButton:hover {
  background-color: #f2f2f2;
}

.Content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding: 0 24px;
  width: 100%;
  max-height: 100%;
  overflow-y: scroll;
}

.Content::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c2c2c2;
}

.ContentHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  width: 100%;
  background-color: #f9fbfc;
  z-index: 10;
}
