.shortcut_list {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
}

.shortcut {
  display: flex;
  gap: 8px;
  align-items: center;
  height: 40px;
  max-width: 280px;
}

.shortcut input {
  width: 166px;
  height: 40px;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  padding: 12px;
  color: #363636;
}

.shortcut input:disabled {
  color: #aaaaaa;
  background-color: #f2f2f2;
}

.shortcut_buttons {
  display: flex;
  gap: 8px;
  position: relative;
  padding: 16px 24px;
}

.blur {
  position: absolute;
  top: -10px;
  width: 284px;
  height: 20px;
}
