.layer_header {
  margin: 0 0 16px 0;
}

.layer_header h1 {
  font-size: 24px;
  font-weight: 400;
}

.layer_header .desc {
  margin: 8px 0 0 0 !important;
  color: #555;
}

.layer .layer_body {
  padding: 16px 0;
}

.layer_linkplus_apply {
  width: 100% !important;
}

.layer_linkplus_apply .tit {
  margin: 16px 0 8px 0;
  font-size: 14px;
  color: #000;
}
.layer_linkplus_apply .tit:first-child {
  margin-top: 0;
}

.layer_linkplus_apply header {
  padding-bottom: 0 !important;
}
.layer_linkplus_apply .tit {
  margin: 16px 0 8px 0;
  font-size: 14px;
  color: #000;
}
.layer_linkplus_apply .tit:first-child {
  margin-top: 0;
}
.layer_linkplus_apply .list_agree li label strong {
  font-size: 15px;
  font-weight: normal;
}
.layer_linkplus_apply .list_agree li {
  margin: 8px 0;
}
.layer_linkplus_apply .content {
  padding-bottom: 24px;
}

.desc {
  margin: 0 !important;
}

.linkplus_apply_btn {
  width: 100%;
  height: 30px !important;
  margin: 10px 0 0 0;
  width: 100%;
  text-align: center;
}

.linkplus_apply_btn_disabled {
  background: #eee;
  color: #888 !important;
  cursor: default;
}
