/* 애니메이션 정의 */
@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOutToLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOutToRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

/* 패널의 기본 스타일 */
.panel {
  top: 0;
  bottom: 0;
  transition: transform 0s ease-in-out;
}

/* 애니메이션 클래스 */
.panel-left-enter {
  animation: slideInFromLeft 0s forwards;
}

.panel-left-exit {
  animation: slideOutToLeft 0s forwards;
}

.panel-right-enter {
  animation: slideInFromRight 0s forwards;
}

.panel-right-exit {
  animation: slideOutToRight 0s forwards;
}
