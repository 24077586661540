.organizer {
  position: absolute;
  right: 0;
  top: 0;
  background: #ffffff;
}

.wrap_organizer {
  position: relative;
  width: 72px;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 12px;
}

.group_link_list {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}
.link_list {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  border-radius: 16px;
}
.link_list:hover {
  background-color: #f2f2f2;
}

.bottom_area {
  display: flex;
  width: 48px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 12px;
  left: 12px;
  gap: 4px;
}

.btn_panel_app {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  position: relative;
}

.panel_active_bar {
  position: absolute;
  top: 0;
  left: -25px;
  height: 32px;
  width: 3px;
  background-color: #08a7bf;
}

.btn_flip {
  position: fixed;
  bottom: 24px;
  right: 0;
  width: 24px;
  height: 32px;
  background-color: #fff;
  border-radius: 8px 0 0 8px;
  display: none;
  cursor: pointer;

  border-top: 1px solid #f2f2f2;
  border-left: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
}

.btn_controller {
  margin: 0px 8px;
  width: 32px;
  height: 20px;
  border-radius: 16px;
}

.divider {
  width: 32px;
  margin: 20px 0px;
}
.divider:before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background: #dddee6;
}

.shortcut_list {
  /* 54 * 7 */
  max-height: calc(100% - 378px);
  min-height: 70px;
  overflow: hidden;
  position: relative;
}
