.Container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 48px 0;
}
.DescriptionGroup {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.Description {
  font-size: 14px;
  font-weight: 500;
  color: #999;
}

.SubDescription {
  font-size: 11px;
  font-weight: 400;
  color: #999;
}

.Icon {
  color: #999;
}
