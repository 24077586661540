.MemoItem {
  @apply flex flex-col justify-between rounded-2xl px-[24px] py-[16px] min-h-[80px];
  border: 1px solid rgba(0, 0, 0, 0.06);
}

.MemoItem:hover {
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.MemoItem:hover .task-list-item[data-checked='true'] p {
  text-decoration: line-through !important;
}

.MemoSummaryItem {
  pointer-events: none;
  @apply h-full overflow-hidden whitespace-normal break-words;
}

.MemoScrollItem {
  @apply min-h-[84px] max-h-[480px] h-full overflow-y-scroll flex-1 mr-[-24px] pr-[12px];
}

.MemoScrollItem::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c2c2c2;
}

.MemoPlusIcon {
  color: #ffffff;
  transform: rotate(0deg);
  transition: transform 0.2s ease-in-out;
}

.MemoPlusIcon:hover {
  transform: rotate(90deg);
}

.MemoIconBtn {
  @apply w-[32px] h-[32px] p-1 rounded-lg bg-transparent hover:bg-[#f2f2f2];
}

.MemoSvgBtn {
  @apply w-[32px] h-[32px] p-1 bg-transparent;
}

.MemoItem:hover .FavoriteBtn {
  display: inline;
}

.FavoriteBtn {
  display: none;
}

.FavoriteBtn.IsFavorite {
  display: inline;
}
